import { BaseFormTypeOld, DateType, DecimalType, MoneyType, NumberType, SelectType, StringType, } from '@f2w/form-new';
import { translate } from 'Services/App';
import { useMemo } from 'react';
import { MortgageType as MortgageInterestType } from 'Components/ObjectViewModal/types';
import Portfolio from 'Models/Portfolio';
export class MortgageType extends BaseFormTypeOld {
    buildFields() {
        const portfolio = Portfolio.instance();
        return {
            propertyId: SelectType.number({
                label: translate('property'),
                required: true,
                searchable: true,
                placeholder: translate('baseData.property.select') + '...',
                defaultValue: () => portfolio.getScopePropertyId(),
                choices: () => {
                    return useMemo(() => {
                        return portfolio.getProperties().map(property => ({
                            label: property.name,
                            value: property.id
                        }));
                    }, []);
                },
                isVisible: (v) => !v.parent.options.isEdit?.()
            }),
            label: new StringType({
                label: translate('mortgage.label'),
                required: true
            }),
            value: new MoneyType({
                label: translate('mortgage.value'),
                required: true
            }),
            start: DateType.start({
                label: translate('mortgage.start'),
                required: true,
                end: v => v.parent.children.end,
            }),
            end: DateType.end({
                label: translate('mortgage.end'),
                required: false,
                start: v => v.parent.children.start,
            }),
            type: SelectType.string({
                label: translate('mortgage.type'),
                required: true,
                choices: () => useMemo(() => {
                    return Object.values(MortgageInterestType).map((value) => ({
                        label: translate('mortgage.type.' + value),
                        value: value,
                    }));
                }, []),
                defaultValue: MortgageInterestType.FIXED
            }),
            interest: new DecimalType({
                label: translate('mortgage.interest'),
                scale: 3,
                prefix: '%',
                isVisible: (v) => v.parent.value.type === MortgageInterestType.FIXED,
                required: true,
                constraints: {
                    moreThan: {
                        value: 0,
                        message: translate('form.validation.notValidNumber')
                    }
                }
            }),
            extraInterest: new NumberType({
                label: translate('mortgage.extraInterest'),
                isVisible: (v) => v.parent.value.type === MortgageInterestType.SARON,
                defaultValue: 0,
                required: true
            }),
            amortisationAmount: new MoneyType({
                label: translate('mortgage.amortisationAmount'),
                required: false
            }),
            amortisationDeadline: new DateType({
                label: translate('mortgage.amortisationDeadline'),
                required: false
            })
        };
    }
}
