import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { translate } from 'Services/Translator';
import generateRoute from 'Services/Router';
import { hasRole } from 'Services/UserManager';
import { hasProductFeature, ProductFeatureType } from 'Services/ProductFeatureManager';
import { getCurrentCustomer, getCurrentUser } from 'Services/UserServices';
import { ContractIcon, LinkIcon, MandateIcon, NewsIcon, SettingsIcon, UserIcon, UsersIcon } from 'Components/App/Icons';
import { Button } from 'Components/Atoms';
import styled from 'styled-components';
import { FormModal } from '@f2w/form';
import { useAccountEditForm } from 'Pages/Account/EditForm/useAccountEditForm';
import { subscribeToCustomEvent, unsubscribeFromCustomEvent } from 'Services/EventManager';
const nameLetter = (name) => name.substring(0, 1);
const UserBarPage = ({ openAccountEditForm }) => {
    const isSupport = hasRole('IS_SUPPORT_USER');
    const { firstName, lastName, jobTitle, profileImage } = getCurrentUser();
    const { name: customerName } = getCurrentCustomer();
    const [userData, setUserData] = useState({
        firstName: firstName,
        lastName: lastName,
        jobTitle: jobTitle,
        profileImage: profileImage,
        customerName: customerName,
    });
    const modalForm = useAccountEditForm();
    React.useEffect(() => {
        if (openAccountEditForm === true) {
            modalForm.openForm(true);
        }
    }, [openAccountEditForm]);
    const updateProfileDataFromEvent = (e) => {
        const data = e.detail;
        setUserData({
            firstName: data.firstName,
            lastName: data.lastName,
            jobTitle: data.jobTitle,
            profileImage: data.image,
            customerName: userData.customerName,
        });
    };
    useEffect(() => {
        subscribeToCustomEvent('fw.profileUpdated', updateProfileDataFromEvent);
        return () => {
            unsubscribeFromCustomEvent('fw.profileUpdated', updateProfileDataFromEvent);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "kt-header__topbar-wrapper", "data-toggle": "dropdown", "data-offset": "10px -2px", children: [_jsxs("div", { className: "kt-header__topbar-user kt-header\\user-toggle kt-hidden-tablet-and-mobile", children: [_jsxs("span", { className: "kt-header__topbar-welcome kt-hidden-mobile", children: [translate('hello'), ","] }), _jsx("span", { className: "kt-header__topbar-username kt-hidden-mobile", children: userData.firstName }), userData.profileImage ?
                                _jsx("img", { src: userData.profileImage, alt: nameLetter(userData.firstName), className: "kt-badge kt-badge--username kt-badge--lg kt-badge--brand kt-hidden-tablet-and-mobile" })
                                :
                                    _jsx("span", { className: "kt-badge kt-badge--username kt-badge--lg kt-badge--brand kt-hidden-tablet-and-mobile", children: nameLetter(userData.firstName) })] }), _jsx("div", { className: "kt-header__topbar-user kt-visible-mobile_ kt-visible-tablet-and-mobile padding_left_15", children: _jsx("div", { className: "kt-visible-mobile_", children: _jsx("div", { className: "kt-badge kt-badge--username kt-badge--brand kt-badge--extra text-center", children: nameLetter(userData.firstName) }) }) })] }), _jsxs("div", { className: "dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-md", children: [_jsx("div", { className: "kt-user-card kt-margin-b-50 kt-margin-b-30-tablet-and-mobile", style: {
                            backgroundImage: "url(/build/assets/media/misc/head_bg_sm.jpg)"
                        }, children: _jsxs("div", { className: "kt-user-card__wrapper", children: [_jsx("div", { className: "kt-user-card__pic", children: userData.profileImage ?
                                        _jsx("img", { src: userData.profileImage, alt: nameLetter(userData.firstName), className: "kt-badge kt-badge--username kt-badge--lg kt-badge--brand" })
                                        :
                                            _jsx("span", { className: "kt-badge kt-badge--username kt-badge--lg kt-badge--brand", children: nameLetter(userData.firstName) }) }), _jsxs("div", { className: "kt-user-card__details", children: [_jsxs("div", { className: "kt-user-card__name", children: [userData.firstName, " ", userData.lastName] }), _jsx("div", { className: "kt-user-card__position", children: jobTitle
                                                ? `${userData.jobTitle}, ${userData.customerName}`
                                                : `${userData.customerName}` })] })] }) }), isSupport ? _jsx(SuperAdminMenu, {}) : _jsx(UserMenu, { onAccountClick: () => modalForm.openForm(true) }), _jsx(FormModal, { modal: modalForm })] })] }));
};
const LogoutNavItem = () => (_jsx("li", { className: "kt-nav__item kt-nav__custom kt-margin-t-15", children: _jsx(Button, { variant: 'outline', color: 'dark', block: true, label: translate('user.logout'), href: generateRoute('app_logout'), icon: 'log-out' }) }));
const SuperAdminMenu = () => (_jsx("ul", { className: "kt-nav kt-margin-b-10", children: _jsx(LogoutNavItem, {}) }));
const NavRoot = styled.ul.attrs(() => ({
    className: 'kt-nav kt-margin-b-10'
})) `
  .kt-nav__link .fw-icon {
    width: 20px;
    height: 20px;
  }
`;
const UserMenu = ({ onAccountClick }) => {
    const isAccountAdmin = hasRole('ROLE_ADMINISTRATOR');
    const hasUsersProductFeature = hasProductFeature(ProductFeatureType.USERS);
    return (_jsxs(NavRoot, { children: [_jsx("li", { className: "kt-nav__item", children: _jsxs("a", { href: generateRoute('profile'), className: "kt-nav__link", children: [_jsx(UserIcon, {}), _jsx("span", { className: "ml-3 kt-nav__link-text", children: translate('user.profile') })] }) }), isAccountAdmin && _jsxs(_Fragment, { children: [_jsx("li", { className: "kt-nav__item", children: _jsxs("a", { onClick: (e) => { e.preventDefault(); onAccountClick(); }, href: "#", className: "kt-nav__link", children: [_jsx(SettingsIcon, {}), _jsx("span", { className: "ml-3 kt-nav__link-text", children: translate('account') })] }) }), _jsx("li", { className: "kt-nav__item", children: _jsxs("a", { href: generateRoute('subscriptions'), className: "kt-nav__link", children: [_jsx(ContractIcon, {}), _jsx("span", { className: "ml-3 kt-nav__link-text", children: translate('subscription.table') })] }) }), hasUsersProductFeature && _jsx("li", { className: "kt-nav__item", children: _jsxs("a", { href: generateRoute('account.users'), className: "kt-nav__link", children: [_jsx(UsersIcon, {}), _jsx("span", { className: "ml-3 kt-nav__link-text", children: translate('account.users') })] }) }), _jsx("li", { className: "kt-nav__item", children: _jsxs("a", { href: generateRoute('mandates'), className: "kt-nav__link", children: [_jsx(MandateIcon, {}), _jsx("span", { className: "ml-3 kt-nav__link-text", children: translate('account.mandates') })] }) }), hasRole('ROLE_ADMINISTRATOR') && hasProductFeature(ProductFeatureType.TEMPLATES) &&
                        _jsx("li", { className: "kt-nav__item", children: _jsxs("a", { href: generateRoute('documentTemplates.new-list'), className: "kt-nav__link", children: [_jsx(ContractIcon, {}), _jsx("span", { className: "ml-3 kt-nav__link-text", children: translate('navigation.templates') })] }) }), _jsx("li", { className: "kt-nav__item", children: _jsxs("a", { href: generateRoute('list_integrations'), className: "kt-nav__link", children: [_jsx(LinkIcon, {}), _jsx("span", { className: "ml-3 kt-nav__link-text", children: translate('account.integrations') })] }) }), _jsx("li", { className: "kt-nav__item", children: _jsxs("a", { href: "https://www.fairwalter.com/ressourcen/updates", className: "kt-nav__link", target: "_blank", children: [_jsx(NewsIcon, {}), _jsx("span", { className: "ml-3 kt-nav__link-text", children: translate('account.newsAndUpdates') })] }) })] }), _jsx(LogoutNavItem, {})] }));
};
export default UserBarPage;
