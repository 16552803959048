import { createElement as _createElement } from "react";
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { useEventCallback } from '@restart/hooks';
import { setDebugVariable } from '@f2w/utils';
import generateRoute from 'Services/Router';
import { translate } from 'Services/Translator';
import { DocumentTemplate } from '../types/DocumentTemplate';
import { FieldValueErrorType } from 'Pages/DocumentTemplates/enums/FieldValueErrorType';
import { Checkbox, TextEditor } from 'Atoms/Input/controls';
import { Button, ButtonGroup } from 'Atoms/Button';
import { Title } from 'Atoms/Text';
import { Icon } from 'Atoms/Icon';
import { FlexCol, FlexRow } from 'Atoms/Layout';
import { SpinnerNew } from 'Atoms/Spinner';
import TooltipOld from 'Components/Tooltip';
const DocumentTemplatesEditPage = ({ initialDocumentTemplate, initialDocumentTemplate: { id: templateId, templateInstanceKey: templateName, templateInstanceKeyTrans: templateTrans, mandate: mandate, fields: _initialFields, ..._initialConfig } = {}, availableUserScopeKeys: availableScopeKeys = [], }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [formData, setFormData] = useState({ ..._initialConfig });
    const [fieldsData, setFieldsData] = useState([..._initialFields]);
    const updateConfig = useEventCallback((value) => {
        setFormData(prev => ({ ...prev, ...value }));
    });
    const updateConfigKey = useEventCallback((key, value) => {
        updateConfig({ [key]: value });
    });
    const resetConfig = useCallback(() => {
        setFormData({ ..._initialConfig });
        setFieldsData([..._initialFields]);
    }, [_initialConfig, _initialFields]);
    const updateFieldValue = useEventCallback((fieldName, value) => {
        setFieldsData(prev => prev.map(field => (field.name !== fieldName ? field : {
            ...field,
            value: value ?? '',
        })));
    });
    setDebugVariable({
        tpl: {
            initial: initialDocumentTemplate,
            ...formData,
            fields: fieldsData
        }
    });
    const handleSubmit = useCallback(async () => {
        if (loading)
            return;
        setLoading(true);
        setError(undefined);
        return DocumentTemplate.editAction({
            ...formData,
            id: templateId ?? null,
            fields: fieldsData,
            instanceKey: templateName,
            mandateId: mandate?.id,
        })
            .then(() => {
            window.location.href = generateRoute('documentTemplates.list');
        })
            .catch((error) => {
            if (error?.response?.status === 400 && error?.response?.data) {
                setError(error?.response?.data);
            }
            else {
                console.error(error);
            }
        })
            .finally(() => {
            setLoading(false);
        });
    }, [loading, templateId, templateName, formData, fieldsData]);
    const availableScopeKeysTooltipContent = React.useMemo(() => {
        const middleIndex = Math.ceil(availableScopeKeys.length / 2);
        const groups = [
            availableScopeKeys.slice(0, middleIndex),
            availableScopeKeys.slice(middleIndex),
        ].filter(group => group?.length > 0);
        return (_jsxs(_Fragment, { children: [_jsxs("div", { children: [translate('documentTemplates.supportedKeys'), ":"] }), _jsx(FlexRow, { children: groups.map((group, groupKey) => (_jsx("ul", { children: group.map(key => (_jsx("li", { children: key }, key))) }, `group-${groupKey}`))) })] }));
    }, [availableScopeKeys]);
    const fieldDataContent = React.useMemo(() => {
        return fieldsData.map((field) => {
            let hasError = false;
            if (error) {
                if (error.type === FieldValueErrorType.SYNTAX_ERROR) {
                    hasError = !!error.values.find(fieldName => field.name === fieldName);
                }
                else {
                    hasError = !!Object.keys(error.values).find(fieldName => field.name === fieldName);
                }
            }
            return (_jsxs("div", { className: "form-group", children: [_jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsxs(FlexCol, { children: [_jsx("label", { children: _jsx("h4", { children: field.title }) }), _jsx("small", { className: "form-text text-muted", children: field.description })] }), _jsx("div", { children: availableScopeKeys.length > 0 && (_jsx(TooltipOld, { placement: "left", overlay: _jsx(_Fragment, { children: availableScopeKeysTooltipContent }), children: _jsx("div", { children: _jsx(Icon, { size: '1.5em', icon: 'info' }) }) })) })] }), _jsx(TextEditor, { hasError: hasError, placeholder: field.description, initialValue: field.value, editorType: field.richTextMode, onChange: (value) => updateFieldValue(field.name, value), onBlur: () => { } })] }, field.name));
        });
    }, [fieldsData, error]);
    const errorContent = React.useMemo(() => {
        if (!error) {
            return _jsx(_Fragment, {});
        }
        return (_jsx("div", { className: "alert alert-danger", role: "alert", children: _jsx("div", { className: "alert-text", children: error.type === FieldValueErrorType.SYNTAX_ERROR
                    ? translate('documentTemplates.error.invalidSyntax')
                    : (_jsxs("div", { children: [_jsxs("div", { children: [translate('documentTemplates.error.unsupportedKeys'), ":"] }), _jsx("ul", { children: Object.values(error.values).flat(1).map((unsupportedKey) => (_jsx("li", { children: unsupportedKey }, unsupportedKey))) })] })) }) }));
    }, [error]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "row", children: [_jsxs("div", { className: "col-md-8", children: [_jsxs("small", { children: [translate('templates'), "/"] }), _jsxs("h2", { children: [templateTrans, "\u00A0", mandate ? _jsxs(_Fragment, { children: ["(", mandate.name, ")"] }) : null] }), _jsx("div", { children: errorContent })] }), _jsx("div", { className: "col-md-4 pt-3", children: _jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsx("h4", { children: translate('templates.alignment') }), _jsx(SpinnerNew, { isVisible: loading })] }) })] }), _jsx("div", { className: "row mt-5 mb-5", children: _jsx("div", { className: "col-md-12", children: _jsx(FlexRow, { alignCenter: true, flexWrap: true, children: _jsxs(FlexRow, { alignCenter: true, gap: 60, children: [_jsxs("div", { children: [_jsx(Title, { small: 500, transform: 'uppercase', value: translate('templates.activateHeader') }), _jsxs(FlexRow, { alignCenter: true, gap: 10, mt: 8, children: [_jsx(Checkbox, { label: ' ', checked: formData.headerActivated, onChange: (checked) => {
                                                        updateConfig({ headerActivated: checked });
                                                    } }), _jsx(ActionGroup, { actions: ['left', 'center', 'right'], buttonProps: { disabled: !formData.headerActivated }, onChange: value => updateConfig({ headerPosition: value }), activeKey: formData.headerPosition })] })] }), _jsxs("div", { children: [_jsx(Title, { small: 500, transform: 'uppercase', value: translate('templates.logo') }), _jsx(ActionGroup, { style: { marginTop: 8 }, actions: ['left', 'center', 'right'], onChange: value => updateConfig({ logoPosition: value }), activeKey: formData.logoPosition })] }), _jsxs("div", { children: [_jsx(Title, { small: 500, transform: 'uppercase', value: translate('templates.address') }), _jsx(ActionGroup, { style: { marginTop: 8 }, actions: ['left', 'right'], onChange: value => updateConfig({ addressPosition: value }), activeKey: formData.addressPosition })] })] }) }) }) }), _jsx("div", { className: "row", children: _jsx("div", { className: "col-md-8", children: fieldDataContent }) }), _jsx("div", { className: "row mt-3", children: _jsx("div", { className: "col-md-12", children: _jsxs("div", { className: "pull-right", children: [_jsx("a", { className: "btn btn-link mr-3", href: generateRoute('documentTemplates.list'), children: _jsx("strong", { children: translate('btn.back') }) }), _jsxs("button", { disabled: loading, onClick: () => resetConfig(), className: "btn btn-secondary mr-3", children: [_jsx("i", { className: "fa fa-refresh" }), " ", translate('btn.reset')] }), _jsx("button", { disabled: loading, className: "btn btn-primary", onClick: () => handleSubmit(), children: translate('btn.save') })] }) }) })] }));
};
const actionGroupMap = {
    '': { icon: 'eye-off' },
    ["left"]: { icon: 'align-left' },
    ["center"]: { icon: 'align-center' },
    ["right"]: { icon: 'align-right' },
    ["justify"]: { icon: 'align-justify' },
};
const ActionGroup = ({ actions, activeKey, onChange, prepend, buttonProps, append, ...props }) => (_jsxs(ButtonGroup, { variant: 'outlineFill', size: 'sm', color: 'neutral', ...props, children: [prepend, actions.map((key, i) => (_createElement(Button, { ...buttonProps, ...actionGroupMap[key], key: `action-${i}`, active: key === activeKey, style: { fontSize: 17 }, onClick: () => {
                onChange?.(key === activeKey ? null : key);
            } }))), append] }));
export default DocumentTemplatesEditPage;
