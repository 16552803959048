import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
const Actions = ({ cell: { value: invoice }, row, refresh, onEditDebitorClicked, onSendDebitorClicked, onDeleteDebitorClicked, onRemindDebitorClicked, onViewDebitorClicked, openManualPaymentForm, openSplitPaymentForm }) => {
    const isDraft = invoice.currentPlace === 'draft';
    return (_jsx(_Fragment, { children: _jsx(ActionsDropdown, { id: row.id, scrollable: true, quickActions: [
                {
                    icon: "eye",
                    title: translate('debitor.actions.view'),
                    onClick: () => {
                        onViewDebitorClicked(row.id);
                    }
                },
                isDraft && {
                    icon: 'edit',
                    title: translate('btn.edit'),
                    onClick: () => onEditDebitorClicked(invoice.id)
                }
            ], children: ({ Link }) => (_jsxs(_Fragment, { children: [isDraft && _jsx(_Fragment, { children: _jsx(Link, { icon: 'check', label: translate('debitor.actions.invoice'), title: translate('debitorInvoice.invoice'), onClick: () => onSendDebitorClicked(row.id) }) }), invoice.isPayable && _jsx(_Fragment, { children: _jsx(Link, { icon: 'credit-card', label: translate('debitor.actions.pay'), title: translate('debitorInvoice.pay'), onClick: () => openManualPaymentForm(invoice.id) }) }), invoice.hasMainPayments && invoice.state.key.toUpperCase() === "OVERPAID" && _jsx(_Fragment, { children: _jsx(Link, { icon: 'divide-circle', label: translate('debitor.actions.split'), title: translate('debitorInvoice.split'), onClick: () => openSplitPaymentForm(invoice.id) }) }), _jsx(Link, { onClick: () => onDeleteDebitorClicked(invoice.id, refresh), icon: 'trash', label: translate('btn.delete') }), _jsx(Link, { onClick: () => onRemindDebitorClicked(invoice.id, refresh), icon: 'alert-circle', label: translate('debitor.actions.remind') })] })) }) }));
};
export default Actions;
