import generateRoute from 'Services/Router';
import client from 'Utils/client';
export const getMortgages = (params) => {
    const route = generateRoute('list_mortgages');
    return client
        .get(route, { params })
        .then(response => response.data);
};
export const getMortgagesStats = (params) => {
    const route = generateRoute('mortgages_stats');
    return client
        .get(route, { params })
        .then(response => response.data);
};
export const getMortgagesRentStats = () => {
    const route = generateRoute('mortgages_rents');
    return client
        .get(route)
        .then(response => response.data);
};
export const createMortgage = (createMortgageDto) => {
    const route = generateRoute('api.mortgage.create');
    return client.post(route, createMortgageDto);
};
export const updateMortgage = (mortgageId, updateMortgageDto) => {
    const route = generateRoute('api.mortgage.update', {
        id: mortgageId
    });
    return client.put(route, updateMortgageDto);
};
export const deleteMortgage = (mortgageId) => {
    const route = generateRoute('api.mortgage.delete', {
        id: mortgageId
    });
    return client.delete(route);
};
export const getMortgageById = (id) => {
    const route = generateRoute('api.mortgage.getById', { id });
    return client
        .get(route)
        .then(response => response.data)
        .then(response => ({
        id: response.mortgage.itemId,
        ...response.mortgage.mortgage,
        amortisationDeadline: new Date(response.mortgage.mortgage.amortisationDeadline),
    }));
};
