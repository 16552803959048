import { jsx as _jsx } from "react/jsx-runtime";
import { parseJson } from 'Utils/parseJson';
import { registerEntrypoint } from '@fw/FairwalterApp';
import { DocumentTemplatesListPage } from './List/DocumentTemplatesListPage';
import DocumentTemplateGlobalSettingsPage from './GlobalSettings/DocumentTemplateGlobalSettingsPage';
import DocumentTemplatesEditV2Page from './EditV2/DocumentTemplatesEditV2Page';
import DocumentTemplatesEditPage from './Edit/DocumentTemplatesEditPage';
registerEntrypoint('DocumentTemplatesListPageEntry', (props) => (_jsx(DocumentTemplatesListPage, { ...props })));
registerEntrypoint('DocumentTemplateEditV2PageEntry', {
    resolveProps: (props) => {
        if (props?.initialDocumentTemplate)
            props.initialDocumentTemplate = parseJson(props.initialDocumentTemplate);
        return { ...props };
    },
}, props => _jsx(DocumentTemplatesEditV2Page, { ...props }));
registerEntrypoint('DocumentTemplateGlobalSettingsPageEntry', {}, (props) => _jsx(DocumentTemplateGlobalSettingsPage, { ...props }));
registerEntrypoint('DocumentTemplateEditPageEntry', {
    resolveProps: ({ initialDocumentTemplate, globalTemplateConfig, ...props }) => ({
        ...props,
        initialDocumentTemplate: parseJson(initialDocumentTemplate),
        globalTemplateConfig: parseJson(globalTemplateConfig),
    }),
}, props => _jsx(DocumentTemplatesEditPage, { ...props }));
