import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Div, FlexCol, FlexRow, Icon, Tooltip2 } from 'Components/Atoms';
import { Checkbox } from 'Atoms/Input/controls';
import { translate } from 'Services/App';
import { generate } from 'Services/Router';
import { Card } from 'Atoms/Card';
import ViewModal from 'Components/ObjectViewModal/components/ViewModalStyled';
import { downloadFileFromUrl } from '@f2w/utils';
import { useEventCallback } from '@restart/hooks';
import { useDispatcherApi } from 'Components/Dispatcher';
const SettingsBlock = (props) => {
    const { settings, onChangeSetting, hasMultipleParties, supportsQr } = props;
    return _jsx(Card, { style: { marginBottom: "1rem" }, children: _jsxs(FlexCol, { gap: 10, children: [_jsx(SettingToggle, { disabled: !hasMultipleParties, checked: settings.isSeparatePerParty, onChange: next => onChangeSetting('isSeparatePerParty', next), caption: translate('debitorInvoiceDocumentAreaSettings.isSeparatePerParty.caption'), help: hasMultipleParties
                        ? translate('debitorInvoiceDocumentAreaSettings.isSeparatePerParty.help')
                        : translate('debitorInvoiceDocumentAreaSettings.isSeparatePerParty.disabledHelp') }), _jsx(SettingToggle, { disabled: !supportsQr, checked: settings.includeQR, onChange: next => onChangeSetting('includeQR', next), caption: translate('debitorInvoiceDocumentAreaSettings.includeQR.caption'), help: supportsQr
                        ? translate('debitorInvoiceDocumentAreaSettings.includeQR.help')
                        : translate('debitorInvoiceDocumentAreaSettings.includeQR.disabledHelp') }), _jsx(SettingToggle, { checked: settings.useReceiptDate, onChange: next => onChangeSetting('useReceiptDate', next), caption: translate('debitorInvoiceDocumentAreaSettings.useReceiptDate.caption'), help: translate('debitorInvoiceDocumentAreaSettings.useReceiptDate.help') })] }) });
};
const SettingToggle = (props) => _jsx(_Fragment, { children: _jsxs(FlexRow, { gap: 10, alignCenter: true, children: [_jsx(Div, { children: _jsx(Checkbox, { checked: !!props.checked, onChange: props.onChange, disabled: props.disabled, children: props.caption }) }), props.help && _jsx(Tooltip2, { flip: true, content: props.help, children: _jsx(Icon, { size: "1.4em", icon: "info" }) })] }) });
const DebitorInvoiceDocumentArea = ({ documents, debitorInvoiceId, hasMultipleParties, supportsQr }) => {
    const [settings, setSettings] = useState({
        isSeparatePerParty: false,
        includeQR: supportsQr,
        useReceiptDate: true,
    });
    const dispatcher = useDispatcherApi();
    const onDownload = (document) => {
        const route = generate('download_debitor_invoice.v2', {
            debitorInvoice: debitorInvoiceId,
            template: document.template,
            ...settings
        });
        downloadFileFromUrl({
            url: route,
            onStart: () => dispatcher?.modal?.show?.({
                message: translate("popup.documents.info.title", {}, "messages"),
                help: translate("popup.documents.info.pleaseWait", {}, "messages"),
                loading: true,
                onCancel: false,
            }),
            onError: () => dispatcher?.toast?.error?.({
                message: translate("popup.documents.error.couldNotBeGenerated", {}, "messages"),
            }),
            onFinish: () => dispatcher?.modal?.close?.()
        });
    };
    const onChangeSetting = useEventCallback((key, value) => setSettings(prev => ({ ...prev, [key]: value })));
    return _jsxs(_Fragment, { children: [_jsx(SettingsBlock, { settings: settings, onChangeSetting: onChangeSetting, hasMultipleParties: hasMultipleParties, supportsQr: supportsQr }), documents.map((document, i) => _jsx(React.Fragment, { children: _jsx(FlexRow, { gap: 20, children: _jsx(FlexCol, { flex: "1", children: _jsx(ViewModal.DocumentLink, { icon: "download", downloadIcon: null, label: document.name, onDownload: () => onDownload(document), tooltip: document.isPropertySpecific
                                ? { content: translate('document.isPropertySpecific.help') }
                                : undefined }) }) }) }, document.template))] });
};
export default DebitorInvoiceDocumentArea;
