import { generate } from 'Services/Router';
import client from "Utils/client";
import { downloadFileFromUrl } from "@f2w/utils";
import { translate } from "Services/App";
export const generateDocument = (fileName, template, sourceClass, sourceId, settings = { mandated: false }, context) => {
    const payload = {
        template,
        sourceClass,
        sourceId,
        format: "pdf",
        binary: true,
        ...settings
    };
    const { dispatcher } = context;
    const generator = generate('helper_generate_document', payload);
    downloadFileFromUrl({
        url: generator,
        fileName: fileName ? `${fileName}.pdf` : undefined,
        onStart: () => dispatcher?.modal?.show?.({
            message: translate("popup.documents.info.title", {}, "messages"),
            help: translate("popup.documents.info.pleaseWait", {}, "messages"),
            loading: true,
            onCancel: false,
        }),
        onError: (e) => {
            const messageKey = e?.message || "popup.documents.error.couldNotBeGenerated";
            return dispatcher?.toast?.error?.({
                message: translate(messageKey, {}, "messages")
            });
        },
        onFinish: () => dispatcher?.modal?.close?.()
    });
};
export const generateDocumentAndGetLink = (template, sourceClass, sourceId, mandated = false) => {
    const payload = {
        template,
        sourceClass,
        sourceId,
        mandated,
        format: "pdf",
    };
    const generator = generate('helper_generate_document', payload);
    return $.ajax({
        type: "GET",
        async: true,
        url: generator,
        success: (data) => data
    });
};
export const generateDocumentsBulk = async (params) => {
    const route = generate('api.documentTemplate.generateDocumentsBulk');
    return client.post(route, params);
};
