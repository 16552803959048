import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DataTable } from "@f2w/data-table";
import { translate } from "Services/Translator";
import { ContractProgress } from "Pages/AncillaryExpense/ContractProgress";
import moment from "moment";
import { Text } from "Atoms/Text";
import { formatAmount } from "Services/NumberHelper";
import { useDispatcherApi } from "Components/Dispatcher";
import { generate } from "Services/Router";
import { downloadFileFromUrl } from "@f2w/utils";
import { Td, Tr } from "Atoms/Table";
import { FlexRow } from "Atoms/Layout";
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
const BaseTable = DataTable.factory()(() => ({
    RowActions: ({ onDownload, overviewData, row: { original: { contractId } } }) => {
        return _jsx(ActionsDropdown, { quickActions: [
                {
                    icon: 'download',
                    disabled: !contractId,
                    title: translate('ancillaryExpenseCalculation.invoice'),
                    onClick: () => {
                        onDownload(overviewData.id, contractId);
                    }
                }
            ] });
    },
    columns: {
        rentalObjectName: {
            Header: translate('rentalObject')
        },
        rentalObjectType: {
            Header: translate('rentalObject.type'),
            accessor: v => v.rentalObjectType
                ? translate(`rentalObjectType.${v.rentalObjectType}`)
                : undefined,
        },
        tenants: {
            Header: translate('ancillaryExpenseCalculation.floatingAndFlatFees.tenants'),
            accessor: d => {
                if (d.tenants.length === 0) {
                    return translate('rentalObject.state.vacancy');
                }
                return d.tenants?.map(v => v.name).join(', ');
            },
        },
        grp: {
            Header: '',
            minWidth: 80,
            maxWidth: 100,
            Cell: ({ row: { original: { period } }, overviewData }) => {
                return _jsx(ContractProgress, { calculationFrom: overviewData.periodFrom, calculationTo: overviewData.periodTo, isVacancy: false, contractFrom: period.start, contractTo: period.end });
            }
        },
        period: {
            Header: translate('contract.details.period'),
            Cell: ({ row: { original: { period } } }) => {
                return moment(period.start).format('DD.MM.YYYY') + ' – '
                    + (period.end ? moment(period.end).format('DD.MM.YYYY') : '∞');
            }
        },
        daysCovered: {
            Header: translate('ancillaryExpenseCalculation.duration'),
            Cell: ({ value, row: { original: { totalDays } } }) => value
                ? _jsx(Text, { nowrap: true, value: `${value} / ${totalDays}` })
                : translate('notAvailable')
        },
        costs: {
            Header: _jsx(FlexRow, { w: '100%', justifyFlexEnd: true, children: translate('expenses') }),
            Cell: ({ value }) => _jsx(FlexRow, { justifyFlexEnd: true, children: formatAmount(value) }),
        },
        administrativeCosts: {
            Header: _jsx(FlexRow, { w: '100%', justifyFlexEnd: true, children: translate('ancillaryExpenseCalculation.administrativeCost.short') }),
            Cell: ({ value }) => _jsx(FlexRow, { justifyFlexEnd: true, children: formatAmount(value) }),
        },
        floatingContribution: {
            Header: _jsx(FlexRow, { w: '100%', justifyFlexEnd: true, children: translate('ancillaryExpenseCalculation.paymentsOnAccount') }),
            Cell: ({ value }) => _jsx(FlexRow, { justifyFlexEnd: true, children: formatAmount(value) }),
        },
        balance: {
            Header: _jsx(FlexRow, { w: '100%', justifyFlexEnd: true, children: translate('ancillaryExpenseCalculation.balance') }),
            Cell: ({ value }) => _jsx(FlexRow, { justifyFlexEnd: true, children: formatAmount(value) }),
        }
    },
    Footer: ({ data }) => {
        const costSum = data
            .map(row => row.costs)
            .reduce((a, b) => a + b, 0);
        const adminCostSum = data
            .map(row => row.administrativeCosts)
            .reduce((a, b) => a + b, 0);
        const floatingContributionSum = data
            .map(row => row.floatingContribution)
            .reduce((a, b) => a + b, 0);
        const balanceSum = data
            .map(row => row.balance)
            .reduce((a, b) => a + b, 0);
        return (_jsxs(Tr, { children: [_jsx(Td, { colSpan: 7 }), _jsx(Td, { align: 'right', children: _jsx("strong", { children: formatAmount(costSum) }) }), _jsx(Td, { align: 'right', children: _jsx("strong", { children: formatAmount(adminCostSum) }) }), _jsx(Td, { align: 'right', children: _jsx("strong", { children: formatAmount(floatingContributionSum) }) }), _jsx(Td, { align: 'right', children: _jsx("strong", { children: formatAmount(balanceSum) }) })] }));
    }
}));
export const ContractContributionBillingTable = Object.assign(BaseTable, {
    use: ({ billings, overviewData }) => {
        const dispatcher = useDispatcherApi();
        const onDownload = (calculationId, contractId) => {
            const route = generate('api.ancillaryExpenseCalculations.downloadInvoiceForContract', {
                calculation: calculationId,
                contract: contractId,
            });
            downloadFileFromUrl({
                url: route,
                onStart: () => dispatcher?.modal?.show?.({
                    message: translate("popup.documents.info.title", {}, "messages"),
                    help: translate("popup.documents.info.pleaseWait", {}, "messages"),
                    loading: true,
                    onCancel: false,
                }),
                onError: () => dispatcher?.toast?.error?.({
                    message: translate("popup.documents.error.couldNotBeGenerated", {}, "messages"),
                }),
                onFinish: () => dispatcher?.modal?.close?.()
            });
        };
        return ContractContributionBillingTable.useCreateTable({
            data: billings,
            overviewData,
            onDownload
        });
    }
});
