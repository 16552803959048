import generateRoute from 'Services/Router';
import client from 'Utils/client';
import axios from "axios";
export const getCalculation = (id) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.details', { id });
    return client
        .get(route)
        .then(response => response.data)
        .then(({ id, invoicedAt, title, showCostOverviewWarning, showFlatFeesWarning, period: { from: periodFrom, to: periodTo }, administrativeCost, property: { id: propertyId, name: propertyName }, nbPeriodDays, nbNominalDays, status }) => ({
        id,
        propertyId,
        propertyName,
        title,
        periodFrom,
        periodTo,
        administrativeCost,
        nbPeriodDays,
        nbNominalDays,
        showCostOverviewWarning,
        showFlatFeesWarning,
        status,
        invoicedAt
    }));
};
export const getCalculationPropertyDetails = (propertyId) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.propertyDetails', {
        id: propertyId
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const deleteCalculation = (calculationId) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.delete', {
        calculation: calculationId
    });
    return client.delete(route);
};
export const createCalculationDraft = (data) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.createDraft');
    return client
        .post(route, data)
        .then(response => response.data);
};
export const saveAndBillCalculation = (calculationId) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.saveAndBill', {
        calculation: calculationId
    });
    return client.post(route);
};
export const revertCalculationBilling = (calculationId) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.revertBilling', {
        calculationId: calculationId
    });
    return client.post(route);
};
export const listCalculations = (params) => {
    const route = generateRoute('ancillaryExpenseCalculations.list');
    return client
        .get(route, { params })
        .then(response => response.data);
};
export async function listCalculationFees(calculationId, params) {
    const route = generateRoute('api.ancillaryExpenseCalculations.flatAndFloatingFees', {
        calculationId
    });
    const response = await client.get(route, { params });
    return response?.data;
}
export async function updateFloatingContribution(calculationId, amounts) {
    const route = generateRoute('api.ancillaryExpenseCalculations.floatingContribution.update', {
        calculationId
    });
    const response = await client.post(route, { amounts });
    return response?.data?.results ?? [];
}
export async function listCalculationAccounts(calculationId, params) {
    const route = generateRoute('api.ancillaryExpenseCalculations.expenses', {
        calculation: calculationId
    });
    const response = await client.get(route, { params });
    return response?.data?.expenses ?? [];
}
export async function updateCalculationAccounts(calculationId, data) {
    const route = generateRoute('api.ancillaryExpenseCalculations.expenses.update', { calculationId });
    return await client.put(route, { expenses: data });
}
export async function listDistributions(calculationId) {
    const route = generateRoute('api.ancillaryExpenseCalculations.distributions', {
        calculation: calculationId
    });
    const response = await client.get(route);
    return response?.data?.distributions ?? [];
}
export async function saveDraft(calculationId, basics) {
    const route = generateRoute('api.ancillaryExpenseCalculations.saveDraft', {
        calculationId
    });
    await client.post(route, basics);
}
export const updateFixCostDistributionAmount = (calculationId, data) => {
    const route = generateRoute('api.ancillaryExpense.fixedCostDistribution.updateAmount', {
        calculationId
    });
    return client.patch(route, data);
};
export const saveMeters = (calculationId, data) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.saveMeters', {
        calculationId
    });
    return client.post(route, data);
};
export const vkaExport = (calculationId, data) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.vkaExport', {
        calculationId
    });
    return client.post(route, { accounts: data }, { responseType: 'blob' })
        .then(response => {
        return response.data;
    });
};
export const importVkaFile = (calculationId, uploadFile, validateOnly) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.importVka', {
        calculationId,
        validateOnly
    });
    const formData = new FormData();
    formData.append('file', uploadFile.file);
    return axios
        .post(route, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(response => response.data);
};
export const getContractContributionBillings = async (calculationId) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.contractContributionBillings', {
        calculation: calculationId
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const getFinancialAccountStatement = async (financialAccountId, calculationId, contractId, periodFrom, periodTo) => {
    const route = generateRoute('api.calculations.financialAccountStatement', {
        financialAccountId,
        calculationId,
        contractId
    });
    return client.get(route, { params: { periodFrom, periodTo } })
        .then(response => response.data);
};
export const generateCalculationInvoices = async (calculationId, settings) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.downloadInvoices', { calculation: calculationId });
    return client.post(route, settings);
};
export const refreshCalculationDistributions = async (calculationId) => {
    const route = generateRoute('api.calculations.refreshDistributions', {
        calculation: calculationId
    });
    return client.patch(route);
};
