import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { ContractState } from 'Enums/ContractState';
import { generate } from 'Services/Router';
import { Badge, Button, ButtonGroup, Div, FlexCol, FlexRow } from 'Components/Atoms';
import { ContentTemplateNew, SplitViewTemplate } from '@f2w/view';
import { Header } from 'Atoms/Layout/components';
import { RightSide } from 'Pages/Contract/components/DetailView/components/RightSide';
import QuickschildBlock from 'Pages/Quickschild/components/QuickschildBlock';
import { withParams } from '@f2w/utils';
import { Checkbox } from 'Atoms/Input/controls/Checkbox';
const getStateBadge = (state) => {
    const stateColors = {
        [ContractState.ACTIVE]: 'success',
        [ContractState.UPCOMING]: 'primary',
        [ContractState.TERMINATED]: 'danger',
        [ContractState.NOTICED]: 'warning',
        [ContractState.LIMITED]: 'primary',
    };
    const color = stateColors[state] || 'primary';
    return _jsx(Badge, { "$color": color, children: translate(`contract.state.${state}`) });
};
const HeaderTemplate = ({ view, }) => {
    const contract = view.data;
    const rentalObject = contract.rentalObject;
    const isTerminated = contract.state === ContractState.TERMINATED
        || contract.state === ContractState.NOTICED
        || contract.isNoticed;
    return _jsxs(_Fragment, { children: [_jsx(Header.Avatar, { pretitle: `${translate('contract')} #${contract?.id}`, title: rentalObject.buildingName, subtitle: rentalObject.name, actions: getStateBadge(contract?.state) }), _jsxs(FlexRow, { justifyFlexEnd: true, mt: 20, gap: 10, children: [contract.isVacancy === false && _jsx(QuickschildBlock, { contract: contract }), _jsx(ButtonGroup, { condense: true, children: isTerminated ? _jsx(Button, { outline: true, color: "error", label: translate('contract.details.actions.revertTermination'), disabled: contract.isVacancy, onClick: () => view
                                .state
                                .revertContractTermination(contract.id, view.state.selectedSubContracts)
                                .then(() => view.load(contract.id)), confirm: {
                                message: translate('contract.details.actions.revertTermination.message'),
                                help: (_jsxs(FlexCol, { gap: 15, children: [_jsx(Div, { children: translate('contract.details.actions.revertTermination.confirmationText') }), _jsx(FlexCol, { gap: 5, children: contract.subContracts.filter(sc => sc.isTerminated).map(sc => {
                                                const isChecked = view
                                                    .state
                                                    .selectedSubContracts
                                                    .includes(sc.id);
                                                return (_jsx(Checkbox, { value: isChecked ? 1 : 0, onChange: () => {
                                                        if (isChecked) {
                                                            view.updateState({
                                                                ...view.state,
                                                                selectedSubContracts: view.state.selectedSubContracts.filter(id => id !== sc.id)
                                                            });
                                                        }
                                                        else {
                                                            view.updateState({
                                                                ...view.state,
                                                                selectedSubContracts: [
                                                                    ...view.state.selectedSubContracts,
                                                                    sc.id
                                                                ]
                                                            });
                                                        }
                                                    }, children: sc.name }, sc.id));
                                            }) })] })),
                                btnConfirm: translate('contract.details.actions.revertTermination.confirmButton'),
                            } }) : _jsx(Button, { outline: true, color: "error", label: translate('contract.details.actions.terminate'), disabled: contract.isVacancy, href: withParams(`/app/wizard/contract-notice/${contract.id}`, {
                                referer: generate('contracts.overview', { contractId: contract.id })
                            }) }) })] })] });
};
export const ContractTemplate = (props) => {
    return _jsx(SplitViewTemplate, { ...props, Template: ContentTemplateNew, leftSide: {
            groups: props.view.data.isVacancy === true
                ? ['general']
                : ['general', 'rent', 'ancillaryExpenses', 'persons', 'deposit'],
            Header: HeaderTemplate,
        }, rightSide: {
            groups: props.view.data.isVacancy === true
                ? ['uploads']
                : ['documents', 'uploads'],
            Template: RightSide,
        } });
};
